/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import {
  Button,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  TextField,
  Theme,
  Tooltip,
  Typography,
} from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { useAppSelector, useAppDispatch } from 'hooks';
import { getUserState } from 'utils';
import { ToastActions } from 'state/toast';
import { AuthUserActions } from 'state/authuser';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from 'components';
import { AuthManager, ToastManager } from 'models';
import authDomainDataLoader from 'utils/authDomainHelper';
import { AuthDomains } from 'models/authdomains';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    badge: {
      marginRight: theme.spacing(2),
      padding: theme.spacing(1),
    },
    title: {
      fontWeight: 'bold',
      marginTop: theme.spacing(7),
      marginBottom: theme.spacing(5),
      color: theme.palette.text.primary,
    },
    divider: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(7),
    },
    paddedField: {
      marginBottom: theme.spacing(2),
    },
    extraPaddedField: {
      marginBottom: theme.spacing(3),
    },
    paperDetails: {
      height: 330,
      width: 350,
      padding: theme.spacing(1),
    },
    paperGroups: {
      height: 330,
      width: 170,
      padding: theme.spacing(1),
    },
    paperNotifications: {
      height: 330,
      width: 250,
      padding: theme.spacing(1),
    },
    oktaErrorMessage: {
      color: 'red',
      marginBottom: 10,
    },
    chipsDiv: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(0.5),
      },
    },
    settingsContainer: {
      gap: theme.spacing(2),
      marginBottom: theme.spacing(0.5),
    },
  }),
);

const groups = [
  'Authoriser',
  'Editor',
  'Validator',
  'Observer',
  'UserManager',
  'HelpPublisher',
  'fdv-elevated',
  'fdv-standard',
];
const notificationGroups = ['Authoriser', 'Editor', 'Validator', 'Observer'];

const SelfEditMyDetails = () => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { user, auth } = useAppSelector((state) => state);
  const userName = auth.object?.decoded_token?.['cognito:username'];
  // Form input variables
  const [firstnameValue, setFirstname] = useState(user.user.object?.firstName);
  const [lastnameValue, setLastname] = useState(user.user.object?.lastName);
  const [receiveObserverEmails, setReceiveObserverEmails] = useState(user.user.object?.receiveObserverEmails);
  const [receiveValidatorEmails, setReceiveValidatorEmails] = useState(user.user.object?.receiveValidatorEmails);
  const [receiveEditorEmails, setReceiveEditorEmails] = useState(user.user.object?.receiveEditorEmails);
  const [receiveAuthoriserEmails, setReceiveAuthoriserEmails] = useState(user.user.object?.receiveAuthoriserEmails);

  const [loadingValue, setLoading] = useState(false);
  // const [authProvIsOkta, setAuthProvIsOkta] = useState(false);
  const [authProvider, setAuthProvider] = useState<AuthManager.AuthProvider>(AuthManager.AuthProvider.CognitoDefault);

  const dispatch = useAppDispatch();

  useEffect(() => {
    authDomainDataLoader(dispatch).then((result: AuthDomains[]) => {
      const providerIds = result.reduce((acc, authData) => {
        acc[authData.provider_name] = authData.provider_id;
        return acc;
      }, {} as Record<string, number>);

      const { OktaRfs, SimpleSamlDfes, CognitoDefault } = AuthManager.AuthProvider;
      const identityProviderId = user.user.object?.identityProviderId;

      if (identityProviderId === providerIds[OktaRfs]) {
        setAuthProvider(OktaRfs);
      } else if (identityProviderId === providerIds[SimpleSamlDfes]) {
        setAuthProvider(SimpleSamlDfes);
      } else if (identityProviderId === providerIds[CognitoDefault]) {
        setAuthProvider(CognitoDefault);
      }
    });
  });

  useEffect(() => {
    if (user.user.status === 'idle' && auth.status === 'finished') {
      if (auth?.object) {
        dispatch(AuthUserActions.getSelf());
      }
    }
    if (user.user.object) {
      setFirstname(user.user.object?.firstName);
      setLastname(user.user.object?.lastName);
    }
  }, [auth?.object, auth.status, dispatch, user.user.status, history, userName]);

  if (user.user.status === 'loading') {
    return (
      <div style={{ textAlign: 'center', marginTop: theme.spacing(5) }}>
        <CircularProgress aria-valuetext="loading" />
      </div>
    );
  }

  // All attributes that must contain a string for form to be valid
  const isValid = [firstnameValue, lastnameValue].filter((x) => !x).length === 0;
  const cancelLocation = window.location.pathname.includes('fdv.afdrs') ? '/menu' : '/';

  if (!user) {
    return <>User not found</>;
  }

  const fieldIsEnabled = (fieldLabel: string) => {
    // function that defines which buttons / fields
    // should be enabled under each auth provider
    switch (authProvider) {
      case AuthManager.AuthProvider.CognitoDefault: {
        if (fieldLabel == 'Firstname') return true;
        if (fieldLabel == 'Lastname') return true;
        break;
      }
      case AuthManager.AuthProvider.OktaRfs: {
        if (fieldLabel == 'Firstname') return false;
        if (fieldLabel == 'Lastname') return false;
        break;
      }
      case AuthManager.AuthProvider.SimpleSamlDfes: {
        if (fieldLabel == 'Firstname') return false;
        if (fieldLabel == 'Lastname') return false;
        break;
      }
      default: {
        return false;
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`${event.target.name} was pressed`);
    switch (event.target.name) {
      case 'Observer':
        setReceiveObserverEmails(event.target.checked);
        break;
      case 'Editor':
        setReceiveEditorEmails(event.target.checked);
        break;
      case 'Validator':
        setReceiveValidatorEmails(event.target.checked);
        break;
      case 'Authoriser':
        setReceiveAuthoriserEmails(event.target.checked);
        break;
      default:
        throw new Error(`Unhandled role: ${event.target.name}`);
    }
  };

  const handleSubmit = async () => {
    try {
      await dispatch(
        AuthUserActions.updateSelf({
          receive_observer_emails: typeof receiveObserverEmails === 'undefined' ? true : receiveObserverEmails,
          receive_validator_emails: typeof receiveValidatorEmails === 'undefined' ? true : receiveValidatorEmails,
          receive_editor_emails: typeof receiveEditorEmails === 'undefined' ? true : receiveEditorEmails,
          receive_authoriser_emails: typeof receiveAuthoriserEmails === 'undefined' ? true : receiveAuthoriserEmails,
          given_name: firstnameValue || '',
          family_name: lastnameValue || '',
          // userIsOkta: authProvIsOkta,
          auth_provider: authProvider,
        }),
      );
      await dispatch(AuthUserActions.getSelf());
      dispatch(
        ToastActions.showToast({
          message: `${firstnameValue} ${lastnameValue} updated`,
          type: ToastManager.ToastType.success,
        }),
      );
    } catch (e: any) {
      dispatch(
        ToastActions.showToast({
          message: e?.data?.detail || `Error encountered updating user`,
          type: ToastManager.ToastType.error,
        }),
      );
    } finally {
      setLoading(false);
    }
  };

  const renderChipsGroup = (group: string) => {
    const userGrps = user.user.object?.roles.map((x) => x.name);
    if (userGrps?.includes(group)) {
      return <Chip key={group} className={classes.badge} label={group} size="small" />;
    }
    return '';
  };

  const renderCheckBoxesGroup = (group: string) => {
    const userGrps = user.user.object?.roles.map((x) => x.name);
    let isChecked = false;
    switch (group) {
      case 'Observer':
        isChecked = receiveObserverEmails === true;
        break;
      case 'Validator':
        isChecked = receiveValidatorEmails === true;
        break;
      case 'Editor':
        isChecked = receiveEditorEmails === true;
        break;
      case 'Authoriser':
        isChecked = receiveAuthoriserEmails === true;
        break;
      default:
        isChecked = false;
    }
    if (userGrps?.includes(group) && notificationGroups.includes(group)) {
      return (
        <FormControlLabel
          key={group}
          control={<Checkbox color="primary" name={group} onChange={handleChange} checked={isChecked} />}
          label={`${group} emails`}
        />
      );
    }
    return '';
  };

  const userLabel = <InputLabel disabled={false}>Email</InputLabel>;
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Typography variant="h4" className={classes.title}>
            {user.user.object?.firstName} {user.user.object?.lastName} ({getUserState(auth?.object)?.toUpperCase()})
          </Typography>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />

      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Grid container justifyContent="flex-start" className={classes.settingsContainer}>
            <Grid key={0} item>
              <Paper className={classes.paperDetails}>
                <h4>Details</h4>
                <Tooltip title={user.user.object?.userName ?? ''}>
                  <FormControl fullWidth>
                    <TextField
                      id="username-input"
                      disabled
                      label={userLabel}
                      className={classes.paddedField}
                      value={user.user.object?.email}
                      fullWidth
                    />
                  </FormControl>
                </Tooltip>
                <TextField
                  className={classes.extraPaddedField}
                  label="Firstname"
                  value={firstnameValue}
                  fullWidth
                  placeholder="John"
                  onChange={(e) => setFirstname(e.target.value)}
                  disabled={!fieldIsEnabled('Firstname')}
                />

                <TextField
                  className={classes.extraPaddedField}
                  label="Lastname"
                  value={lastnameValue}
                  fullWidth
                  placeholder="Smith"
                  onChange={(e) => setLastname(e.target.value)}
                  disabled={!fieldIsEnabled('Lastname')}
                />
              </Paper>
            </Grid>
            <Grid key={1} item>
              <Paper className={classes.paperGroups}>
                <h4>Groups</h4>
                <div className={classes.chipsDiv}>{Object.values(groups).map(renderChipsGroup)}</div>
              </Paper>
            </Grid>
            <Grid key={2} item>
              <Paper className={classes.paperNotifications}>
                <h4>Email Notifications</h4>
                {Object.values(groups).map(renderCheckBoxesGroup)}
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container style={{ marginBottom: 16 }}>
        <Grid item xs={12} />
        <Grid item xs={1} />
        <Grid item xs={11}>
          <PrimaryButton
            disabled={!isValid}
            size="large"
            onClick={handleSubmit}
            loading={loadingValue}
            style={{ width: 150 }}
          >
            Update
          </PrimaryButton>{' '}
          <Button size="large" onClick={() => navigate(cancelLocation)} style={{ width: 150 }}>
            Cancel
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SelfEditMyDetails;
