/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef, useState } from 'react';
import { useTheme, withStyles, makeStyles } from '@material-ui/core/styles';
import { Theme, SvgIcon, Typography, Tooltip } from '@material-ui/core';
import { PlayArrow, Pause } from '@material-ui/icons';
import { LayerManager } from 'models';
import { useAppDispatch, useAppSelector, usePrevious } from 'hooks';
import cloneDeep from 'lodash/cloneDeep';
import { createStyles } from '@material-ui/core/styles';
import { LayerActions } from 'state/layers';

// Interface imports
import TimesliderProps from './timeslider-subcomponents/interfaces/timesliderProps';

import calcTickSizeHelper from './timeslider-subcomponents/helper_functions/calcTickSize';
import startDraggingSliderEventHandler from './timeslider-subcomponents/event_handlers/startDraggingSlider';
import stopDraggingSliderEventHandler from './timeslider-subcomponents/event_handlers/stopDraggingSlider';

import generateForecastDaysStyles from './timeslider-subcomponents/helper_functions/generateForecastDaysStyles';
import generateDaySegments from './timeslider-subcomponents/helper_functions/generateDaySegments';
import getLatestTimeHelper from './timeslider-subcomponents/helper_functions/getLatestTime';
import getActiveTab from './timeslider-subcomponents/helper_functions/getActiveTab';
import searchEarliestTimeHelper from './timeslider-subcomponents/helper_functions/searchEarliestTime';
import dragSliderHelper from './timeslider-subcomponents/event_handlers/dragSlider';
import getDateFromMarginOffsetHelper from './timeslider-subcomponents/helper_functions/getDateFromMarginOffset';
import toggleSpeedEventHandler from './timeslider-subcomponents/event_handlers/toggleSpeed';
import scrollToTabEventHandler from './timeslider-subcomponents/event_handlers/scrollToTab';
import prevTimeStepEventHandler from './timeslider-subcomponents/event_handlers/prevTimeStep';
import normaliseDateHelper from './timeslider-subcomponents/helper_functions/normaliseDate';
import setCurrentTimeHelper from './timeslider-subcomponents/helper_functions/setCurrentTime';
import intervalFunctionEventHandler from './timeslider-subcomponents/event_handlers/intervalFunction';
import restartIntervalEventHandler from './timeslider-subcomponents/event_handlers/restartInterval';
import nextTimeStepHelper from './timeslider-subcomponents/helper_functions/nextTimeStep';

// * CONSTANTS

const hourInMs = 3600000;

// How often the red 'time now' bar should update and move.
// Should be slow enough to now cause perforamnce issues
// but fast enough that the line isn't noticed to be out of place.
// 2 minutes appears to do this well.
const CURRENT_TIME_UPDATE_INTERVAL = 2 * 60 * 1000; // 2 minutes

// These are SVGs used as labels for the '1x', '2x', and '3x' playback speed modes
// for the time slider UI controls
const times1Path = (
  <path
    d="M14.2674 20V11.6H12.9114C12.8634 11.92 12.7634 12.188 12.6114 12.404C12.4594 12.62 12.2714 12.796 12.0474 12.932C11.8314 13.06 11.5834 13.152 11.3034 13.208C11.0314 13.256 10.7474 13.276 10.4514 13.268V14.552H12.5634V20H14.2674ZM18.2233 16.736L15.9913 20H17.8993L19.1953 18.044L20.4913 20H22.4353L20.1433 16.7L22.1833 13.796H20.2993L19.2193 15.416L18.1273 13.796H16.1833L18.2233 16.736Z"
    fill="black"
    style={{ transform: 'scale(1.5) translate(-5px, -5px)' }}
  />
);

const times2Path = (
  <path
    d="M10.0074 14.828H11.6394C11.6394 14.604 11.6594 14.38 11.6994 14.156C11.7474 13.924 11.8234 13.716 11.9274 13.532C12.0314 13.34 12.1674 13.188 12.3354 13.076C12.5114 12.956 12.7234 12.896 12.9714 12.896C13.3394 12.896 13.6394 13.012 13.8714 13.244C14.1114 13.468 14.2314 13.784 14.2314 14.192C14.2314 14.448 14.1714 14.676 14.0514 14.876C13.9394 15.076 13.7954 15.256 13.6194 15.416C13.4514 15.576 13.2634 15.724 13.0554 15.86C12.8474 15.988 12.6514 16.116 12.4674 16.244C12.1074 16.492 11.7634 16.736 11.4354 16.976C11.1154 17.216 10.8354 17.48 10.5954 17.768C10.3554 18.048 10.1634 18.368 10.0194 18.728C9.88336 19.088 9.81536 19.512 9.81536 20H15.9834V18.536H12.0114C12.2194 18.248 12.4594 17.996 12.7314 17.78C13.0034 17.564 13.2834 17.364 13.5714 17.18C13.8594 16.988 14.1434 16.796 14.4234 16.604C14.7114 16.412 14.9674 16.2 15.1914 15.968C15.4154 15.728 15.5954 15.456 15.7314 15.152C15.8674 14.848 15.9354 14.484 15.9354 14.06C15.9354 13.652 15.8554 13.284 15.6954 12.956C15.5434 12.628 15.3354 12.352 15.0714 12.128C14.8074 11.904 14.4994 11.732 14.1474 11.612C13.8034 11.492 13.4394 11.432 13.0554 11.432C12.5514 11.432 12.1034 11.52 11.7114 11.696C11.3274 11.864 11.0074 12.104 10.7514 12.416C10.4954 12.72 10.3034 13.08 10.1754 13.496C10.0474 13.904 9.99136 14.348 10.0074 14.828ZM18.2233 16.736L15.9913 20H17.8993L19.1953 18.044L20.4913 20H22.4353L20.1433 16.7L22.1833 13.796H20.2993L19.2193 15.416L18.1273 13.796H16.1833L18.2233 16.736Z"
    fill="black"
    style={{ transform: 'scale(1.5) translate(-5px, -5px)' }}
  />
);

const times3Path = (
  <path
    d="M12.2994 14.996V16.196C12.5074 16.196 12.7234 16.204 12.9474 16.22C13.1794 16.228 13.3914 16.272 13.5834 16.352C13.7754 16.424 13.9314 16.544 14.0514 16.712C14.1794 16.88 14.2434 17.124 14.2434 17.444C14.2434 17.852 14.1114 18.176 13.8474 18.416C13.5834 18.648 13.2594 18.764 12.8754 18.764C12.6274 18.764 12.4114 18.72 12.2274 18.632C12.0514 18.544 11.9034 18.428 11.7834 18.284C11.6634 18.132 11.5714 17.956 11.5074 17.756C11.4434 17.548 11.4074 17.332 11.3994 17.108H9.77936C9.77136 17.596 9.83936 18.028 9.98336 18.404C10.1354 18.78 10.3474 19.1 10.6194 19.364C10.8914 19.62 11.2194 19.816 11.6034 19.952C11.9954 20.088 12.4274 20.156 12.8994 20.156C13.3074 20.156 13.6994 20.096 14.0754 19.976C14.4514 19.856 14.7834 19.68 15.0714 19.448C15.3594 19.216 15.5874 18.928 15.7554 18.584C15.9314 18.24 16.0194 17.848 16.0194 17.408C16.0194 16.928 15.8874 16.516 15.6234 16.172C15.3594 15.828 14.9954 15.604 14.5314 15.5V15.476C14.9234 15.364 15.2154 15.152 15.4074 14.84C15.6074 14.528 15.7074 14.168 15.7074 13.76C15.7074 13.384 15.6234 13.052 15.4554 12.764C15.2874 12.476 15.0674 12.232 14.7954 12.032C14.5314 11.832 14.2314 11.684 13.8954 11.588C13.5594 11.484 13.2234 11.432 12.8874 11.432C12.4554 11.432 12.0634 11.504 11.7114 11.648C11.3594 11.784 11.0554 11.98 10.7994 12.236C10.5514 12.492 10.3554 12.8 10.2114 13.16C10.0754 13.512 9.99936 13.904 9.98336 14.336H11.6034C11.5954 13.904 11.6994 13.548 11.9154 13.268C12.1394 12.98 12.4674 12.836 12.8994 12.836C13.2114 12.836 13.4874 12.932 13.7274 13.124C13.9674 13.316 14.0874 13.592 14.0874 13.952C14.0874 14.192 14.0274 14.384 13.9074 14.528C13.7954 14.672 13.6474 14.784 13.4634 14.864C13.2874 14.936 13.0954 14.98 12.8874 14.996C12.6794 15.012 12.4834 15.012 12.2994 14.996ZM18.2233 16.736L15.9913 20H17.8993L19.1953 18.044L20.4913 20H22.4353L20.1433 16.7L22.1833 13.796H20.2993L19.2193 15.416L18.1273 13.796H16.1833L18.2233 16.736Z"
    fill="black"
    style={{ transform: 'scale(1.5) translate(-5px, -5px)' }}
  />
);

interface TimesliderStylesProps {
  activeTabBorderRadius: number;
  activeTabHeight: number;
  activeTabWidth: number;
  tabActiveTextContainerMarginLeft: number;
  tabBorderRadius: number;
  tabHeight: number;
  tabSpacing: number;
  tabWidth: number;
}

const useStyles = makeStyles<Theme, TimesliderStylesProps>((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: 'auto auto 1fr',
      borderTop: `1px solid ${theme.palette.common.neutralXLight}`,
      color: theme.palette.common.white,
      height: 120,
      outline: '0px',
      justifySelf: 'baseline',
      width: '100%',
    },
    actionIcon: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      fontSize: '40px',
      borderRadius: '40px',
      margin: theme.spacing(0.5),
      padding: theme.spacing(0.25),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.palette.common.neutralXXLight,
      },
    },
    timezoneText: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
    },
    leftSection: {
      padding: theme.spacing(0),
    },
    controlsSection: {
      padding: theme.spacing(1),
      borderBottom: `solid 1px ${theme.palette.common.neutralXLight}`,
    },
    timeZoneSection: {
      padding: theme.spacing(1),
    },
    sliderSection: {
      padding: `${theme.spacing(0)}px ${theme.spacing(2)}px`,
      placeItems: 'center',
      overflowX: 'auto',
      overflowY: 'hidden',
      display: 'grid',
      height: '100%',
      borderLeft: `1px solid ${theme.palette.common.neutralXLight}`,
      position: 'relative',
      minWidth: '350px',
    },
    activeTab: {
      position: 'absolute',
      left: theme.spacing(2),
      display: 'grid',
      gridAutoFlow: 'column',
      zIndex: 10,
      height: '100%',
      alignContent: 'center',
      pointerEvents: 'none',
      top: 0,
    },
    activeTabCurrentTime: {
      position: 'absolute',
      left: theme.spacing(2),
      display: 'grid',
      gridAutoFlow: 'column',
      zIndex: 10,
      height: '100%',
      alignContent: 'center',
      pointerEvents: 'stroke',
      top: 0,
      cursor: 'pointer',
    },
    daySegment: (props) => ({
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      minWidth: props.tabWidth * 24 + props.tabSpacing * 23,
      borderLeft: `2px dashed ${theme.palette.common.neutralLight}`,
      marginLeft: props.tabSpacing,
    }),
    dayText: {
      color: theme.palette.common.neutralDark,
      fontWeight: 'bold',
      margin: theme.spacing(0.5),
    },
    timeText: {
      color: theme.palette.common.white,
      fontWeight: 'bold',
      margin: `0px ${theme.spacing(0.5)}px`,
    },
    tabsSegment: (props) => ({
      position: 'absolute',
      display: 'grid',
      gridAutoFlow: 'column',
      transform: `translateX(-${props.tabWidth / 2 + (props.tabSpacing + 1)}px)`,
      zIndex: 5,
    }),
    tabNormal: (props) => ({
      backgroundColor: theme.palette.common.neutralLight,
      borderRadius: props.tabBorderRadius,
      marginLeft: props.tabSpacing,
      height: props.tabHeight,
      cursor: 'pointer',
      '&:focus': {
        outline: '0px',
      },
    }),
    tabDark: (props) => ({
      backgroundColor: theme.palette.common.neutralDark,
      borderRadius: props.tabBorderRadius,
      marginLeft: props.tabSpacing,
      height: props.tabHeight,
      cursor: 'pointer',
      '&:focus': {
        outline: '0px',
      },
    }),
    tabInactive: (props) => ({
      // wasn't a noticable difference from neutral, neutralXXLight not noticeable from white
      backgroundColor: '#EAEDEE', // theme.palette.common.neutralXLight,
      borderRadius: props.tabBorderRadius,
      marginLeft: props.tabSpacing,
      height: props.tabHeight,
      cursor: 'pointer',
      '&:focus': {
        outline: '0px',
      },
    }),
    tabActive: (props) => ({
      backgroundColor: theme.palette.common.black,
      borderRadius: props.activeTabBorderRadius,
      marginLeft: props.tabSpacing,
      height: props.activeTabHeight,
      width: props.activeTabWidth,
      cursor: 'pointer',
      '&:focus': {
        outline: '0px',
      },
      transform: `translateX(-${props.tabSpacing + 0.2}px)`,
    }),
    tabActiveTextContainer: (props) => ({
      backgroundColor: theme.palette.common.black,
      borderRadius: 8,
      width: 140,
      height: 'min-content',
      marginLeft: props.tabActiveTextContainerMarginLeft,
      marginTop: -48,
    }),
    dottedLineSegment: {
      display: 'grid',
      gridTemplateRows: '1fr 1fr',
      position: 'absolute',
      width: '100%',
      height: '100%',
    },
    halfLineSegment: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    quarterDaySegment: {
      borderLeft: `2px dashed ${theme.palette.common.neutralLight}`,
    },
  }),
);

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(18),
    padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
    borderRadius: 12,
  },
}))(Tooltip);

// * TIMESLIDER COMPONENT

const Timeslider: React.FunctionComponent<TimesliderProps> = ({
  layers,
  onTimeChange,
  viewCount,
  offsetsMap,
  drawerOpen,
}) => {
  // ! PRE INIT

  // This is necessary to maintain consistency of what is the 'true' value of the
  // layer timesteps.
  const copyLayers = cloneDeep(layers) as LayerManager.Layer[] | undefined;

  const hoursPerStep = calcTickSizeHelper({ layers: copyLayers });
  const { selectedTime } = useAppSelector((state) => state.layers);

  const dispatch = useAppDispatch();

  const theme = useTheme();

  // Used when determining the minimum number of day segments to generate
  const forecastDays: number[] = [];

  // Used to dynamically generate the number of days to generate.
  // Hourly layers get an extra day as a buffer
  const uniqueTimesteps: Date[] = [];
  copyLayers?.forEach((layer) => {
    layer.timeSteps?.forEach((timeStep) => {
      const copyTimestep = new Date(timeStep);
      copyTimestep.setHours(0, 0, 0, 0);
      if (!uniqueTimesteps.some((ts) => ts.getTime() === copyTimestep.getTime())) {
        uniqueTimesteps.push(copyTimestep);
      }
    });
  });

  for (
    let idx = 0;
    idx < uniqueTimesteps.length + 1 * +(hoursPerStep === 1 || uniqueTimesteps.length === 7 || hoursPerStep === 3);
    idx++
  ) {
    forecastDays.push(idx);
  }

  let defaultTabWidth = 12;
  // Calculate the width for day time slider based on the screen available width
  if (hoursPerStep == 24) {
    const sliderMenuWidth = 150;
    // If the drawer is open, set the width to be the greater of 365px or 22% of the screen width.
    const drawerWidth = drawerOpen ? Math.max(365, (window.innerWidth * 22) / 100) : 0;
    const screenWidth = window.innerWidth - sliderMenuWidth - drawerWidth;
    const totalWidthRequired = forecastDays.length * (24 * defaultTabWidth + 24 * defaultTabWidth * 0.4);

    if (totalWidthRequired > screenWidth) {
      const scaleFactor = screenWidth / totalWidthRequired;
      defaultTabWidth = Math.max(3, defaultTabWidth * scaleFactor);
    }
  }

  const tabWidth = defaultTabWidth;
  const tabHeight = 24;
  const tabBorderRadius = 3;
  const tabSpacing = tabWidth * 0.4;
  const activeTabWidth = 12;
  const activeTabHeight = tabHeight * 1.72;
  const activeTabBorderRadius = activeTabWidth / 4;
  const tabActiveTextContainerMarginLeft = -70 + activeTabWidth / 2;

  const classes = useStyles({
    activeTabBorderRadius: activeTabBorderRadius,
    activeTabHeight: activeTabHeight,
    activeTabWidth: activeTabWidth,
    tabActiveTextContainerMarginLeft: tabActiveTextContainerMarginLeft,
    tabBorderRadius: tabBorderRadius,
    tabHeight: tabHeight,
    tabSpacing: tabSpacing,
    tabWidth: tabWidth,
  });

  // Reference forecast day styles
  const forecastDaysClasses = generateForecastDaysStyles({
    forecastDaysOtherLayer: forecastDays,
  })();

  const prevLayers = usePrevious(layers);
  const prevViewCount = usePrevious(viewCount);

  const [now, setNowDate] = useState(new Date());
  const timeOffset = now.getTimezoneOffset(); // Different between local time and UTC in minutes

  const setNow = () => setNowDate(new Date());

  // This useEffect kicks off an interval that keeps the red 'time now' bar in the correct place.
  useEffect(() => {
    const updateTime = setInterval(setNow, CURRENT_TIME_UPDATE_INTERVAL);
    return () => {
      clearInterval(updateTime);
    };
  });

  // Apply the time offsets to the layers (if necessary)
  if (offsetsMap !== undefined) {
    for (const key in offsetsMap) {
      const layer = copyLayers?.find((e) => e.id === key);

      if (layer?.timeSteps === undefined || layer?.timeSteps === null) {
        continue;
      }

      // Apply the offset to every timestep in the layer
      for (let i = 0; i < layer?.timeSteps.length; i++) {
        layer.timeSteps[i].setTime(layer.timeSteps[i].getTime() + offsetsMap[key] * (1000 * 60 * 60));
      }
    }
  }

  // ! VARIABLES

  // Flag to keep track of whether the timeslider is in 'play' or 'pause' mode.
  const [isPlayingInterval, setisPlayingInterval] = useState<null | NodeJS.Timeout>(null);

  // Flag to keep track of the playback speed the user has set
  const [playbackSpeedIndex, setplaybackSpeedIndex] = useState(0);

  // Mouse UI elements
  const [mouseDragPosition, setMouseDragPosition] = useState<number | null>(null);
  const sliderTabRef = useRef<HTMLDivElement>(null); // Handle to the black slider tab on the time slider
  const sliderSection = useRef<HTMLDivElement>(null);
  const sliderTabTextRef = useRef<HTMLDivElement>(null); // Handle to the text above the black slider tab on the time slider

  // this internal state variable is different from the selectedTime prop in that it tracks the *displayed* timeslider time
  // eg if selectedTime is 13th Dec 13:00:00 and timeSlider is showing daily timesteps - currentTime will be 13th Dec 00:00:00
  const [currentTime, setStateCurrentTime] = useState(selectedTime);

  // Used to determine when the earliest and latest start date for the LIST of layers (need a time slider to fit all layers)
  const [earliestTime] = searchEarliestTimeHelper({ layers: copyLayers });
  const latestTime = getLatestTimeHelper({ layers: copyLayers });

  // Text that displays the users current timezone.
  // Standards List: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
  const timeZoneText = `UTC ${timeOffset < 0 ? '+' : '-'}${`${Math.abs(Math.floor(timeOffset / -60))}`.padStart(
    2,
    '0',
  )}:${`${Math.abs(timeOffset) % -60}`.padStart(2, '0')}`;

  // Get the latest start time
  // is just start time when 1 layer, when multiple it's the latest of all start times
  const latestStart = LayerManager.getLatestLayerStartDate(copyLayers);

  // This 'startOfDay' variable controls the time of the first tab in the timeslider.
  // Every subsequent tab's time is computed RELATIVE TO THE FIRST TAB
  const startOfDay = earliestTime && new Date(earliestTime);

  const timeStepOffsets = {
    offsetFor24HourLayers: ((earliestTime?.getHours() ?? 0) + (earliestTime?.getMinutes() ?? 0) / 60) % 24,
    offsetFor3HourLayers: ((earliestTime?.getHours() ?? 0) + (earliestTime?.getMinutes() ?? 0) / 60) % 3,
    offsetFor1HourLayers: ((earliestTime?.getHours() ?? 0) + (earliestTime?.getMinutes() ?? 0) / 60) % 1,
  };

  startOfDay?.setHours(0, 0, 0, 0);

  // Used to calculate the position of the red bar for the time slider and in getting the active tab
  const hoursFromStart =
    currentTime && startOfDay
      ? Math.abs(Math.floor((currentTime.getTime() - startOfDay.getTime()) / hourInMs))
      : undefined;

  /**
   * The CSS 'left margin' value in pixels describing where to draw the
   * activeTab and the time now display.
   * When being dragged, it's set to the drag position.
   */
  let sliderTabMarginLeft = hoursFromStart != null ? hoursFromStart * tabWidth + hoursFromStart * tabSpacing : -250;

  if (mouseDragPosition) sliderTabMarginLeft = mouseDragPosition;

  /**
   * Controls the left margin of time now display.
   * It preferred to keep it in the middle of the activeTab, but will shift right
   * to prevent it from sitting off the left edge of the timeslider.
   * See 'sliderTabMarginLeft'.
   */
  const sliderTabTextMarginLeft =
    sliderTabMarginLeft < 0
      ? sliderTabMarginLeft
      : Math.max(sliderTabMarginLeft, Math.abs(tabActiveTextContainerMarginLeft));

  // Used by the various interval functions (e.g. restartInterval)
  const initialIntervalDate = new Date();

  /**
   * Current time minus the first timestep in milliseconds.
   * See 'currentTimeMarginLeft' for usage.
   *
   * NOTE:
   * ADSTVisOffset is subtracted from the offset amount since the 'start' of the
   * red line offset is from the 'start of the current day'.
   * As without this subtraction, the red line (which shows the current time)
   * will be a 24 hrs ahead.
   */
  const copiedDate = new Date(now?.getTime());
  copiedDate.setHours(0, 0, 0, 0);

  // Used by the `currentTimeMarginLeft` variable. Ultimately sets the position of the 'red bar'
  // (current time) on the timeslider.
  const timeNowHoursFromStart = now && copiedDate ? (now.getTime() - copiedDate.getTime()) / hourInMs : undefined;

  /**
   *  The selected tab is the only one not attached to a DaySegment
   *  It represents the selected time and is required to be draggable.
   *  It's not attached to a day segment as it must be able to be dragged across the entire timeslider.
   *  It's simply drawn on top of the tabs below.
   *  Is undefined when not in a positition to be drawn,
   *  ie: before the first day, or after the last day.
   */
  const activeTab = getActiveTab({
    currentTime,
    forecastDays,
    hourInMs,
    hoursFromStart,
    startOfDay,
    tabSpacing,
    tabWidth,
    classes,
  });

  /**
   * Used to calculate the position of the red vertical 'time now' line.
   */
  let timeNowComboHoursFromStart = timeNowHoursFromStart;
  if (earliestTime && timeNowComboHoursFromStart !== undefined && startOfDay !== undefined && startOfDay !== null) {
    const startOfToday = new Date(now);
    const startOfEarliestDay = new Date(earliestTime);

    startOfToday.setHours(0, 0, 0, 0);
    startOfEarliestDay.setHours(0, 0, 0, 0);

    const daysBehind = Math.floor((startOfToday.getTime() - startOfEarliestDay.getTime()) / (1000 * 60 * 60 * 24)) * 24;
    timeNowComboHoursFromStart += daysBehind * +(earliestTime?.getTime() < startOfToday.getTime());
  }

  const currentTimeMarginLeft = timeNowComboHoursFromStart
    ? timeNowComboHoursFromStart * tabWidth +
      (timeNowComboHoursFromStart - 1 >= 0 ? timeNowComboHoursFromStart - 1 : 0) * tabSpacing +
      tabSpacing +
      tabWidth / 2
    : -250;

  /**
   * Represents the dynamicly calculated normalised date while the
   * TimeSlider active tab is being dragged.
   * As dragging is handled as a side effect, we don't want a full redraw to occur or a state update.
   * This date is specifically only used as a comparison to the dragged point raw time to work out if in fact
   * the slider has crossed a new normalised time. When it has, only then is the timeslider state updated.
   *
   * This should be safe to define here (as opposed to a state variable) as only 1 timeslider can ever
   * be dragged at once and this value is only used during the drag action.
   */
  let dragDate: Date | null = null;

  /**
   * Used for when the slider is being dragged to calculate the date from
   * the specific pixel location of the slider. This is then normalised to
   * figure out what time is selected by this.
   * @param timesliderPixeloffset Number of pixels from the left of the timeslider.
   * @returns The date represented by the provided offset.
   */
  const getDateFromMarginOffset = (timesliderPixeloffset: number): Date | null => {
    return getDateFromMarginOffsetHelper({
      timesliderPixeloffset,
      hoursPerStep,
      tabSpacing,
      activeTabWidth,
      tabWidth,
      timeStepOffsets,
      hourInMs,
      startOfDay,
    });
  };

  /**
   * Using the units of switches per second (1 switch / X seconds)
   * Should be specifically crafted to make use of the
   * '1x', '2x', '3x' SVGs defined above.
   * Should this need to be changed then the icons should be reviewed.
   * See 'playBackIcons'.
   */
  const playBackSpeeds = [1 / 6, 2 / 6, 3 / 6];

  // ! HELPER FUNCTIONS

  /**
   * Will normalise a provided date. In the context of this timeslider this means that
   * for any raw date/time, the returned date will match a selectable time.
   * In other words will will convert a raw date to the date that matches any of the drawn tabs on the slider.
   * @param date The date to normalise
   * @returns A normalised date
   */
  const normaliseDate = (date: Date): Date => {
    return normaliseDateHelper({
      date,
      hoursPerStep,
      startOfDay,
      hourInMs,
      timeStepOffsets,
      timeOffsetMins: timeOffset,
      latestTime,
      earliestTime,
    });
  };

  /**
   * Sets the timesliders time to the given time, and
   * calculates layer-specific timesteps for it.
   * @param date Will update the timeslider state to this Date
   * @param initial Whether this represents the initial time update. This will occur when the timeslider is first built and when a new layer is added.
   */
  const setCurrentTime = (date?: Date | null, initial?: boolean) => {
    dispatch(LayerActions.setSelectedTime(date));

    setCurrentTimeHelper({
      setStateCurrentTime,
      date,
      initial,
      layers: copyLayers,
      onTimeChange,
    });
  };

  /**
   * A convienience function.
   * Will first normalise the given date (see 'normaliseDate') if the required values are set.
   * Then will set the current time to this normalised value.
   * @param date The date to normalise then use to set the time
   * @param initial See 'initial' in the function 'setCurrentTime'
   */
  const setAndNormaliseCurrentTime = (date?: Date | null, initial?: boolean) => {
    if (date && hoursPerStep && startOfDay) {
      setCurrentTime(normaliseDate(date), initial);
    } else {
      setCurrentTime(date, initial);
    }
  };

  // ! UI CONTROL CALLBACKS

  const scrollToTab = async () => {
    scrollToTabEventHandler({
      sliderSection,
      sliderTabRef,
    });
  };

  /**
   * The function to be called at the playback Speed interval.
   * Each call of this function will increment the time to the next selectable time.
   * If it gets to the last time (see 'latestTime') then it will return to
   * the 'earliestTime', effectively looping the playback.
   * It will also scroll to ensure the tab stays in the middle.
   */
  const intervalFunction = () => {
    initialIntervalDate.setTime(
      intervalFunctionEventHandler({
        currentTime,
        latestTime,
        earliestTime,
        hoursPerStep,
        normaliseDate,
        setCurrentTime,
        setAndNormaliseCurrentTime,
        scrollToTab,
        initialIntervalDate,
        hourInMs,
      }).getTime(),
    );
  };

  /**
   * When the playback speeds are toggled, the old playback needs to be stopped and started again.
   * If it isn't playing, it will start playing if this is called.
   * @param newPlaybackSpeed The new desired speed.
   */
  const restartInterval = (newPlaybackSpeed?: number) => {
    restartIntervalEventHandler({
      currentTime,
      intervalFunction,
      isPlayingInterval,
      newPlaybackSpeed,
      playbackSpeedIndex,
      playBackSpeeds,
      setisPlayingInterval,
    });
  };

  /**
   * When called, the next timeslider will move to the next time step and sroll to it if necessary.
   * Used in the Keyboard event callback when the right arrow is pressed.
   */
  const nextTimeStep = () => {
    let currTimeAndHrPerStepNotNullFlag = false;
    let setCurrentTimeFlag = false;
    let isPlayingIntervalFlag = false;
    let newTime: Date | null = null;

    let numTimesteps;
    if (latestTime && earliestTime && hoursPerStep) {
      numTimesteps =
        (normaliseDate(latestTime).getTime() - normaliseDate(earliestTime).getTime()) / (hoursPerStep * 1000 * 60 * 60);
    }

    // Process the request with the helper
    [currTimeAndHrPerStepNotNullFlag, setCurrentTimeFlag, isPlayingIntervalFlag, newTime] = nextTimeStepHelper({
      currentTime,
      hoursPerStep,
      latestTime,
      hourInMs,
      normaliseDate,
      isPlayingInterval,
      timesteps: numTimesteps,
    });

    // Make the changes based on the response
    if (currTimeAndHrPerStepNotNullFlag) {
      if (setCurrentTimeFlag) {
        setCurrentTime(newTime);
      } else {
        setAndNormaliseCurrentTime(earliestTime);
        if (isPlayingIntervalFlag) restartInterval();
      }
    }
    scrollToTab();
  };

  /**
   * When called, the next timeslider will move to the previous time step and scroll to it if necessary.
   * Used in the Keyboard event callback when the left arrow is pressed.
   */
  const prevTimeStep = () => {
    prevTimeStepEventHandler({
      currentTime,
      hoursPerStep,
      hourInMs,
      earliestTime,
      latestTime,
      normaliseDate,
      setCurrentTime,
      setAndNormaliseCurrentTime,
      scrollToTab,
    });
  };

  /**
   * Mouse Down event handler for initialising the slider drag logic.
   */
  const startDraggingSlider = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    startDraggingSliderEventHandler({
      event,
      activeTabWidth,
      isPlayingInterval,
      setMouseDragPosition,
    });
  };

  /**
   * Handles the mouse up event and effectively stops the slider from being dragged
   * and update the timeslider state to be the final drag normalised date.
   */
  const stopDraggingSlider = () => {
    let sliderTabRefCurrentFlag = false;
    let marginLeftNotNaNFlag = false;
    let isPlayingIntervalFlag = false;
    let marginLeftReturnVar = null;

    [sliderTabRefCurrentFlag, marginLeftNotNaNFlag, isPlayingIntervalFlag, marginLeftReturnVar] =
      stopDraggingSliderEventHandler({ sliderTabRef, isPlayingInterval });

    if (sliderTabRefCurrentFlag && marginLeftNotNaNFlag && marginLeftReturnVar != null) {
      const newTime = getDateFromMarginOffset(marginLeftReturnVar);
      setCurrentTime(newTime);
      if (currentTime && newTime) {
        initialIntervalDate.setTime(newTime.getTime());
      }
    }
    dragDate = null;
    setMouseDragPosition(null);
    // restart interval when dragging stops
    if (isPlayingIntervalFlag) restartInterval();
  };

  /**
   * Handles with slider drag event update.
   * It will calculate the hover date from the pixel offests and using 'dragDate'
   * when it detects a new normalised date has been crossed. Then the timeslider
   * state and date will update.
   * This allows the map to render as the slider is being dragged.
   */
  const dragSlider = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    let newMargin = null;
    let newMarginText = null;
    let newDate = null;
    let newDateCheck = false;

    [newMargin, newMarginText, newDate, newDateCheck] = dragSliderHelper({
      event,
      sliderTabRef,
      sliderTabTextRef,
      activeTabWidth,
      tabActiveTextContainerMarginLeft,
      getDateFromMarginOffset,
      dragDate,
      currentTime,
    });

    // Check the slider
    if (sliderTabRef.current && sliderTabTextRef.current) {
      sliderTabRef.current.style.marginLeft = `${newMargin}px`;
      sliderTabTextRef.current.style.marginLeft = `${newMarginText}px`;
    }

    if (newDateCheck) {
      dragDate = newDate;
      setCurrentTime(newDate);
    }
  };

  /**
   * The onClick callback for the playback speed icons.
   * If it's playing it will also restart the interval.
   * This toggle will loop over the available values in 'playBackSpeeds'.
   */
  const toggleSpeed = () => {
    toggleSpeedEventHandler({
      playbackSpeedIndex,
      setplaybackSpeedIndex,
      isPlayingInterval,
      restartInterval,
      playBackSpeeds,
    });

    // This is done to ensure upon toggling the speed, the initialIntervalDate isn't reset
    // back to `new Date()`
    if (currentTime) {
      initialIntervalDate.setTime(new Date(currentTime).getTime());
    }
  };

  /**
   * Will start the playback at the current time.
   */
  const play = () => {
    if (currentTime) {
      initialIntervalDate.setTime(new Date(currentTime).getTime());
      restartInterval();
    }
  };

  /**
   * Will stop the playback
   */
  const stop = () => {
    if (isPlayingInterval) clearInterval(isPlayingInterval);
    setisPlayingInterval(null);
  };

  // ! HTML SUB-COMPONENTS

  const times1Svg = (
    <SvgIcon viewBox="0 0 32 32" className={classes.actionIcon} onClick={toggleSpeed}>
      {times1Path}
    </SvgIcon>
  );

  const times2Svg = (
    <SvgIcon viewBox="0 0 32 32" className={classes.actionIcon} onClick={toggleSpeed}>
      {times2Path}
    </SvgIcon>
  );

  const times3Svg = (
    <SvgIcon viewBox="0 0 32 32" className={classes.actionIcon} onClick={toggleSpeed}>
      {times3Path}
    </SvgIcon>
  );

  /**
   * The list of icons to be used for each playback speed.
   * See 'playbackSpeeds'.
   */
  const playBackIcons = [times1Svg, times2Svg, times3Svg];

  /**
   * Variable containing the 'DaySegment' HTML sub-components
   * ( these are the boxes denoting 'MON DD/MM' )
   * NOTE : prevDay and currDay are passed via reference.
   */
  const days = generateDaySegments({
    earliestTime,
    latestTime,
    hoursPerStep,
    startOfDay,
    forecastDays,
    timeStepOffsets,
    classes,
    hourInMs,
    tabWidth,
    tabSpacing,
  });

  // ! POST INIT

  // This useEffect handles the adding/removing of layers from the layer list
  // by normalising the selectedTime to a valid timestep on the slider
  useEffect(() => {
    // if no timed layers are onscreen - no point normalising selectedTime
    if (!copyLayers || copyLayers.length < 1 || !latestStart) {
      stop();
      return;
    }

    // First find out what has been added and what has been removed.
    // Both added/removed are added to this 'missing' list.
    const missing: string[] = [];
    prevLayers?.forEach((pl) => {
      if ((copyLayers ?? []).findIndex((l) => pl.id === l.id) === -1) missing.push(pl.id);
    });
    copyLayers?.forEach((l) => {
      if ((prevLayers ?? [])?.findIndex((pl) => pl.id === l.id) === -1) missing.push(l.id);
    });

    if (missing.length > 0) {
      // layers.length > 1 will be for the compare tool
      if (currentTime && copyLayers && copyLayers.length > 1) {
        const latestLayerStart = LayerManager.getLatestLayerStartDate(copyLayers);
        // when changing data source in compare tool select a
        // startup time where all maps have some data (so map
        // does not appear blank)
        if (latestLayerStart && currentTime < latestLayerStart) {
          setTimeout(() => setAndNormaliseCurrentTime(latestLayerStart, true), 50);
        } else {
          setTimeout(() => setAndNormaliseCurrentTime(selectedTime, true), 50);
        }
      } else {
        setTimeout(() => setAndNormaliseCurrentTime(selectedTime, true), 50);
      }
      scrollToTab();
      stop();
    }
  }, [copyLayers]);

  // Handler for when the number of maps (views) have changed
  useEffect(() => {
    if (viewCount && prevViewCount) {
      // pick up scenario when 1 view has been added or removed
      // if so set time to latestLayerStart so all maps show data
      if (Math.abs(prevViewCount - viewCount) === 1) {
        const latestLayerStart = LayerManager.getLatestLayerStartDate(copyLayers);
        setTimeout(() => setAndNormaliseCurrentTime(latestLayerStart, true), 50);
      }
    }
  }, [viewCount]);

  return (
    /* Container for the timeslider */
    <div
      className={classes.root}
      onKeyDown={(event) => {
        if (event.key === 'ArrowLeft') prevTimeStep();
        else if (event.key === 'ArrowRight') nextTimeStep();
      }}
      role="slider"
      aria-valuenow={currentTime?.getTime() ?? NaN}
      tabIndex={0}
    >
      {/* Container for the playback controls and the timezone label */}
      <div className={classes.leftSection}>
        {/* Container for the 'play / pause' and speed circle buttons */}
        <div className={classes.controlsSection}>
          {/* Means the time slider is set to 'play' */}
          {isPlayingInterval != null && (
            <CustomTooltip title="Pause" placement="top">
              <Pause className={classes.actionIcon} onClick={stop} />
            </CustomTooltip>
          )}

          {/* Means the time slider is set to 'pause' */}
          {isPlayingInterval == null && (
            <CustomTooltip title="Play" placement="top">
              <PlayArrow className={classes.actionIcon} onClick={play} />
            </CustomTooltip>
          )}

          {/* Icon for the playback speed (1x, 2x, 3x) */}
          <CustomTooltip title="Speed" placement="top">
            {playBackIcons[playbackSpeedIndex]}
          </CustomTooltip>
        </div>
        {/* Timezone container */}
        <div style={{ padding: theme.spacing(1) }}>
          <Typography variant="subtitle2" align="center" className={classes.timezoneText}>
            {timeZoneText}
          </Typography>
        </div>
      </div>
      {/*
       * Container for the various day segments to be rendered
       * The idea here is we have a parent handle
       */}
      <div ref={sliderSection} className={classes.sliderSection}>
        {/* Container for all the day segments (e.g. Mon DD/MM) */}
        <div
          className={forecastDaysClasses.sliderSectionDays}
          // Inline style element, uses the CSS function `repeat()` + gridTemplateColumns
          // To dynamically create a grid layout. Grids are 1 fractional unit (1fr) apart.
          style={{ gridTemplateColumns: `repeat(${forecastDays.length}, 1fr)` }}
          onMouseDown={(event) => {
            if (event.button === 0) startDraggingSlider(event);
          }}
          onMouseUp={(event) => {
            if (event.button === 0) stopDraggingSlider();
          }}
          onMouseMove={(event) => {
            if (mouseDragPosition != null && event.button === 0) {
              dragSlider(event);
            }
          }}
          onMouseLeave={() => {
            if (mouseDragPosition != null) stopDraggingSlider();
          }}
        >
          {/* HTML for all the DaySegments */}
          {days}
        </div>
        {/* Container for the active tab (black box on the time slider) */}
        <div
          ref={sliderTabRef}
          className={classes.activeTab}
          // Inline style, used to specify the animation behaviour of the
          // active tab (black tab specifying what 'day' or 'hour' is selected)
          style={{
            marginLeft: sliderTabMarginLeft,
            transition:
              mouseDragPosition == null
                ? `${theme.transitions.create(`margin-left`, {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.standard,
                  })}`
                : '',
          }}
        >
          {activeTab}
        </div>
        {/* Container for the container for the current time selected
         * Contains configuration settings for the animation of the
         * active tab label
         */}
        <div
          ref={sliderTabTextRef}
          className={classes.activeTab}
          style={{
            marginLeft: sliderTabTextMarginLeft,
            zIndex: 15,
            transition:
              mouseDragPosition == null
                ? `${theme.transitions.create('margin-left', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.standard,
                  })}`
                : '',
          }}
        >
          {/* Container for the current time selected */}
          <div className={classes.tabActiveTextContainer}>
            {/* The black box specify what the current selected time is */}
            <Typography variant="subtitle2" className={classes.timeText} align="center">
              {currentTime
                ? currentTime
                    .toLocaleDateString('en-AU', {
                      weekday: 'short',
                      day: '2-digit',
                      month: '2-digit',
                      hour: hoursPerStep === 24 ? undefined : '2-digit',
                      minute: hoursPerStep === 24 ? undefined : '2-digit',
                      // @ts-ignore
                      hourCycle: 'h23',
                    })
                    .replace(/,/g, '')
                : 'N/A'}
            </Typography>
          </div>
        </div>
        {/* The 'red bar' denoting the current time on the time slider */}
        <CustomTooltip
          title={new Date().toLocaleString('en-AU', {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            hour12: true,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
          })}
          placement="top"
        >
          <div
            className={classes.activeTabCurrentTime}
            style={{
              borderRight: '2px solid red',
              borderLeft: '2px solid red',
              opacity: 0.5,
              zIndex: 20,
              marginLeft: currentTimeMarginLeft,
              transition: `${theme.transitions.create('margin-left', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.standard,
              })}`,
            }}
          ></div>
        </CustomTooltip>
      </div>
    </div>
  );
};

export default Timeslider;
