import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { transform } from 'ol/proj';

import { Loadable, Common } from 'models';
import { makeAPICall, makeMockAPICall } from './api';
import { haversineDistance, dataURLtoBlob, mimeToExtension, getMimeFromDataURL, isToday } from 'utils';
import { Style, Icon } from 'ol/style';
import { Duration } from 'luxon';

export declare namespace Location {
  export namespace ObservationAPI {
    export interface Geometry {
      type: string;
      coordinates: number[];
    }

    export interface Note {
      note_id: number;
      note: string;
      modified: string;
      user: User;
    }

    export interface User {
      user_id: number;
      user_name: string;
      user_email: string;
      given_name: string | null;
      family_name: string | null;
    }

    export interface Observation {
      observation_id: number;
      observer_id: number;
      observation_time: string;
      created: string;
      validation_time?: string | null;
      fuel_load: number;
      fuel_load_prev: number;
      fuel_condition: number;
      fuel_condition_prev: number;
      curing: number;
      curing_prev: number;
      validator_id?: number;
      is_confident: boolean;
      is_continuous: boolean;
      status: string;
      photo_url: string | null;
      observation_notes: Note[];
      observer: User;
      validator: User;
    }

    export interface Location {
      location_id: number;
      status: string;
      location_name: string;
      geometry: Geometry;
      observations: Observation[] | null;
    }

    export interface HistoricObservations {
      data: Observation[];
      count: number;
    }

    export interface Root {
      data: Location[];
      count: number;
    }
  }

  export namespace LocationAdminAPI {
    export interface Geometry {
      type: string;
      coordinates: number[];
    }

    export interface Location {
      location_id: number;
      status: string;
      location_name: string;
      geometry: Geometry;
      observer_count?: number;
      validator_count?: number;
      fire_management_area_name?: string;
      fire_area_name?: string;
      sub_area_name?: string;
    }

    export interface Root {
      data: Location[];
      count: number;
    }
  }

  export interface Observation {
    id: number;
    created: number;
    time: number;
    curing: number | null;
    curingPrevious: number | null;
    fuelCondition: number | null;
    fuelConditionPrevious: number | null;
    fuelLoad: number | null;
    fuelLoadPrevious: number | null;
    locationId: number;
    confident: boolean;
    continuous: boolean;
    notes: Common.Note[];
    submitted: boolean;
    validated: boolean;
    validatedToday: boolean;
    validationTime?: number;
    observer: Common.User | null;
    validator: Common.User | null;
    imageUrl?: string;
    newNote?: Common.Note;
    notifyChange?: boolean;
    image?: string;
  }
}

export interface EditDetails {
  location: Location;
  update_observer?: boolean;
  id: number;
}

export interface Location {
  id: number;
  name: string;
  age: number | null;
  distance: number | null;
  highlight: boolean;
  lon: number;
  lat: number;
  observations: Location.Observation[];
  newObservation: Loadable<Partial<Location.Observation>>;
}

export const mapAPIDataToObservationLocations = (root: Location.ObservationAPI.Root): Location[] =>
  root.data.map((loc) => ({
    id: loc.location_id,
    name: loc.location_name,
    lon: loc.geometry.coordinates[0],
    lat: loc.geometry.coordinates[1],
    distance: null,
    highlight: false,
    age:
      loc.observations && loc.observations[0]
        ? Date.now() - new Date(loc.observations[0].observation_time).getTime()
        : null,
    observations: loc.observations
      ? loc.observations.map((obs) => {
          const time = new Date(obs.observation_time).getTime();
          const created = new Date(obs.created).getTime();
          const isTimeToday = isToday(new Date(time));
          return {
            time,
            created,
            id: obs.observation_id,
            locationId: loc.location_id,
            curing: obs.curing,
            curingPrevious: obs.curing_prev,
            fuelCondition: obs.fuel_condition,
            fuelConditionPrevious: obs.fuel_condition_prev,
            fuelLoad: obs.fuel_load,
            fuelLoadPrevious: obs.fuel_load_prev,
            confident: obs.is_confident,
            continuous: obs.is_continuous,
            notes: obs.observation_notes.map((note) => ({
              id: note.note_id,
              note: note.note,
              modified: note.modified,
              user: note.user
                ? {
                    id: note.user.user_id,
                    userName: note.user.user_name,
                    email: note.user.user_email,
                    firstName: note.user.given_name,
                    familyName: note.user.family_name,
                  }
                : undefined,
            })),
            observer: obs.observer
              ? {
                  id: obs.observer.user_id,
                  userName: obs.observer.user_name,
                  email: obs.observer.user_email,
                  firstName: obs.observer.given_name,
                  familyName: obs.observer.family_name,
                }
              : null,
            validator: obs.validator
              ? {
                  id: obs.validator.user_id,
                  userName: obs.validator.user_name,
                  email: obs.validator.user_email,
                  firstName: obs.validator.given_name,
                  familyName: obs.validator.family_name,
                }
              : null,
            submitted: obs.status === 'submitted' || (obs.status === 'validated' && isTimeToday),
            validated: obs.status === 'validated',
            validationTime: obs.validation_time ? new Date(obs.validation_time).getTime() : undefined,
            validatedToday:
              obs.status === 'validated' && !!obs.validation_time && isToday(new Date(obs.validation_time)),
            imageUrl: obs.photo_url || undefined,
          };
        })
      : [],
    newObservation: {
      status: 'idle',
    },
  }));

export const mapAPIDataToLocations = (data: Location.LocationAdminAPI.Root) => data.data;

export const mapAPIDataToHistoricObservations = (data: Location.ObservationAPI.HistoricObservations) => data.data;

export const validateObservation = makeAPICall<null, EditDetails>(
  (payload) => {
    const observation = payload.location.newObservation.object;
    const note = observation?.newNote?.note;
    const data = new FormData();

    data.append('status', 'validated');
    if (note) data.append('note', `${note}`);

    return {
      ext: `/observations/${payload.id}`,
      contentType: null,
      requestData: {
        method: 'PUT',
        body: data,
      },
    };
  },
  () => null,
);

export const editObservation = makeAPICall<null, EditDetails>(
  (payload) => {
    const observation = payload.location.newObservation.object ?? {};
    const createTime = observation.time ? new Date(observation.time) : null;
    const note = observation.newNote?.note;
    const fileName = observation.image
      ? `obs-${createTime?.getTime()}.${mimeToExtension[getMimeFromDataURL(observation.image)]}`
      : '';
    const data = new FormData();

    data.append('location_id', `${payload.location.id}`);
    if (observation.fuelLoad !== undefined) data.append('fuel_load', `${observation.fuelLoad ?? ''}`);
    if (observation.fuelCondition !== undefined) data.append('fuel_condition', `${observation.fuelCondition ?? ''}`);
    if (observation.curing !== undefined) data.append('curing', `${observation.curing ?? ''}`);
    if (observation.confident !== undefined) data.append('is_confident', `${observation.confident ?? ''}`);
    if (observation.continuous !== undefined) data.append('is_continuous', `${observation.continuous ?? ''}`);
    if (observation.notifyChange !== undefined) data.append('notify', `${observation.notifyChange ?? ''}`);
    if (createTime) data.append('observation_time', `${createTime?.toISOString()}`);
    data.append('status', 'submitted');

    if (payload.update_observer) data.append('update_observer', 'true');

    if (note) data.append('note', `${note}`);

    if (observation.image) data.append('photo', dataURLtoBlob(observation.image), fileName);

    return {
      ext: `/observations/${payload.id}`,
      contentType: null,
      requestData: {
        method: 'PUT',
        body: data,
      },
    };
  },
  () => null,
);

export const addNewObservation = makeAPICall<null, { location: Location }>(
  (payload) => {
    const observation = payload.location.newObservation.object ?? {};
    const createTime = observation.time ? new Date(observation.time) : null;
    const note = observation.newNote?.note;
    const fileName = observation.image
      ? `obs-${createTime?.getTime()}.${mimeToExtension[getMimeFromDataURL(observation.image)]}`
      : '';
    const data = new FormData();

    data.append('location_id', `${payload.location.id}`);
    data.append('fuel_load', `${observation.fuelLoad}`);
    data.append('fuel_condition', `${observation.fuelCondition}`);
    data.append('curing', `${observation.curing}`);
    data.append('is_confident', `${observation.confident}`);
    data.append('is_continuous', `${observation.continuous}`);
    data.append('observation_time', `${createTime?.toISOString()}`);
    data.append('status', 'submitted');
    if (note) data.append('note', `${note}`);

    if (observation.image) data.append('photo', dataURLtoBlob(observation.image), fileName);

    return {
      ext: `/observations`,
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  () => null,
);

export const getLocationListAsObserver = makeAPICall<Location[], void, Location.ObservationAPI.Root>(
  () => ({
    ext: `/observations/observers`,
  }),
  mapAPIDataToObservationLocations,
);

export const getLocationListAsValidator = makeAPICall<Location[], void, Location.ObservationAPI.Root>(
  () => ({
    ext: `/observations/validators`,
  }),
  mapAPIDataToObservationLocations,
);

export const getLocationList = makeAPICall<Location.LocationAdminAPI.Location[], void, Location.LocationAdminAPI.Root>(
  () => ({
    ext: `/assigned-locations/summary`,
  }),
  mapAPIDataToLocations,
);

export const applyDistances = makeMockAPICall<Location[], void, null>(
  () => ({}),
  (resp, payload, state) => {
    if (state?.observer.locations.status === 'error') throw state.observer.locations.error;
    if (state?.observer.locations.object)
      return state.observer.locations.object.map((loc: any) => ({
        ...loc,
        distance: state.position.object
          ? haversineDistance(
              loc.lat,
              loc.lon,
              state.position.object.coords.latitude,
              state.position.object.coords.longitude,
            )
          : null,
      }));
    return [];
  },
);

export const toFeatures = (locations: Location[]): Feature[] =>
  locations.map((location) => {
    const feature = new Feature({
      geometry: new Point(transform([location.lon, location.lat], 'EPSG:4326', 'EPSG:3857')),
      name: location.name,
      id: location.id,
    });
    let validationIsOld = false;
    if (location.observations.length > 0) {
      const latestObservation = location.observations[0];
      if (Date.now() - latestObservation.time > Duration.fromObject({ weeks: 4 }).as('milliseconds')) {
        validationIsOld = true;
      }
    }

    if (validationIsOld) {
      feature.setStyle(
        new Style({
          image: new Icon({
            src: '/map/marker-orange.png',
          }),
        }),
      );
    } else {
      feature.setStyle(
        new Style({
          image: new Icon({
            src: location.highlight ? '/map/marker-dark.png' : '/map/marker-light.png',
          }),
        }),
      );
    }
    return feature;
  });

export const fuelConditionText = (fuelCondition: number | null): string => {
  if (fuelCondition === null) return 'null';
  if (fuelCondition === 1) return 'Eaten out';
  if (fuelCondition === 2) return 'Grazed';
  if (fuelCondition === 3) return 'Natural';
  return 'unknown';
};

export const addNewLocation = makeAPICall<null, { location: Location.LocationAdminAPI.Location }>(
  (payload) => {
    const data = new FormData();

    const [longitude, latitude] = payload.location.geometry.coordinates;
    data.append('longitude', `${longitude}`);
    data.append('latitude', `${latitude}`);
    data.append('location_name', `${payload.location.location_name}`);

    return {
      ext: '/locations',
      contentType: null,
      requestData: {
        method: 'POST',
        body: data,
      },
    };
  },
  () => null,
);

export const updateLocation = makeAPICall<null, { location: Location.LocationAdminAPI.Location }>(
  (payload) => {
    const data = new FormData();

    data.append('location_name', `${payload.location.location_name}`);
    data.append('loc_status', `${payload.location.status}`);

    return {
      ext: `/locations/${payload.location.location_id}`,
      contentType: null,
      requestData: {
        method: 'PUT',
        body: data,
      },
    };
  },
  () => null,
);

export const getHistoricObservationValues = makeAPICall<Location.ObservationAPI.Observation[], { location: Location }>(
  (payload) => {
    return {
      ext: `/locations/${payload.location.id}/historic`,
      requestData: {
        method: 'GET',
      },
    };
  },
  mapAPIDataToHistoricObservations,
);
