interface NormaliseDateInterface {
  date: Date;
  hoursPerStep: number | null;
  startOfDay: Date | null | undefined;
  hourInMs: number;
  timeStepOffsets: { [timeStepOffset: string]: number };
  timeOffsetMins: number;
  latestTime: Date | null | undefined;
  earliestTime: Date | null | undefined;
}

/**
 * Will normalise a provided date. In the context of this timeslider this
 *  means that for any raw date/time, the returned date will match a selectable
 *  time.
 *
 * In other words will will convert a raw date to the date that matches any of
 *   the drawn tabs on the slider.
 * @param date : The date to attempt to normalise
 * @param hoursPerStep : The number of hours per step
 * @param startOfDay : The timestamp specifying the start of the day
 * @param hourInMs : (constant) 60(min)*60(sec)*1000(ms)
 * @param timeStepOffsets : dictionary of hourly offsets (24/3/1) for current layer
 * @returns the normalised date.
 */
function normaliseDateHelper({
  date,
  hoursPerStep,
  startOfDay,
  hourInMs,
  timeStepOffsets,
  timeOffsetMins,
  latestTime,
  earliestTime,
}: NormaliseDateInterface) {
  if (hoursPerStep == null || startOfDay == null || latestTime == null || earliestTime == null) return date;

  const hoursPastStartOfDay =
    (date.getTime() - startOfDay.getTime() - hourInMs * timeStepOffsets.offsetFor3HourLayers * +(hoursPerStep === 3)) /
    (hoursPerStep * hourInMs);

  // Flag to determine if the hourly layer needs some offset correction.
  const hourlyFractionOffsetCorrection = ((-1 * timeOffsetMins) / 60) % 1;
  let newDate;

  // If the layer is daily round the starting time up.
  // Else (3 hourly/1 hourly) rounds it down
  const tempNewDate =
    hoursPerStep === 24
      ? new Date(startOfDay.getTime() + Math.ceil(hoursPastStartOfDay) * (hoursPerStep * hourInMs))
      : new Date(startOfDay.getTime() + Math.floor(hoursPastStartOfDay) * (hoursPerStep * hourInMs));

  if (tempNewDate <= earliestTime) {
    return earliestTime;
  }

  // For daily layers if the date is outside of the range, fallback to the latest available day start
  // TODO: Need to test this for 3 hourly/1 hourly layer
  if (hoursPerStep === 24 && tempNewDate >= latestTime) {
    return latestTime;
  }

  if (hoursPerStep === 1 && hourlyFractionOffsetCorrection > 0 && tempNewDate <= latestTime) {
    //  TODO: Verify this logic will work when daylight savings starts.
    newDate = new Date(tempNewDate.getTime() + hourlyFractionOffsetCorrection * 60 * 60 * 1000);
  } else {
    newDate = new Date(
      startOfDay.getTime() +
        Math.floor(hoursPastStartOfDay) * (hoursPerStep * hourInMs) +
        hourInMs * timeStepOffsets.offsetFor3HourLayers * +(hoursPerStep === 3),
    );
  }
  return newDate;
}

export default normaliseDateHelper;
