import { makeStyles, createStyles } from '@material-ui/core/styles';

interface GenerateForecastDaysInterface {
  forecastDaysOtherLayer: number[];
}

/**
 * Generates the `useStyles` object used for classes.
 * Having this in another function allows the Timeslider.tsx
 * file to be smaller
 * @returns the `useStyles` object.
 */
function generateForecastDaysStyles({ forecastDaysOtherLayer }: GenerateForecastDaysInterface) {
  const useStyles = makeStyles(() =>
    createStyles({
      sliderSectionDays: {
        display: 'grid',
        height: '100%',
        gridTemplateColumns: `repeat(${forecastDaysOtherLayer.length}, 1fr)`,
        cursor: 'default',
        userSelect: 'none',
      },
    }),
  );

  return useStyles;
}

export default generateForecastDaysStyles;
