/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, PropsWithChildren, useEffect } from 'react';

import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme, Typography } from '@material-ui/core';

import { useAppSelector, useAppDispatch } from 'hooks';
import { LayerActions } from 'state/layers';
import { LayerManager, WmsManager } from 'models';
import { ratingToName, round } from 'utils';

import IndicatorIcon from './IndicatorIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      color: theme.palette.common.grey,
      fontSize: 20,
      fontWeight: 600,
    },
    section: {
      display: 'inline-block',
      padding: theme.spacing(2),
      width: 220,
    },
    lastSection: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
      padding: theme.spacing(2),
    },
    kpiContainer: {
      border: `1px solid ${theme.palette.common.neutralXLight}`,
      borderRadius: 4,
      padding: theme.spacing(1),
      display: 'inline-block',
      margin: `${theme.spacing(1)}px ${theme.spacing(0.5)}px`,
      width: 187,
      height: 72,
      fontSize: 18,
      fontWeight: 500,
    },
    kpiTitle: {
      fontSize: 12,
      color: theme.palette.common.grey,
      fontWeight: 400,
      marginBottom: theme.spacing(1),
    },
  }),
);

const KpiContainer: FunctionComponent<PropsWithChildren<{ name: string }>> = ({ name, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.kpiContainer}>
      <div className={classes.kpiTitle}>{name}</div>
      {children}
    </div>
  );
};

const textLayers: LayerManager.Layer.LayerIds[] = ['IDZ10136_AUS_AFDRS_max_fdr_SFC', 'IDZ10134_AUS_AFDRS_fdr_SFC'];

const KeyFactors: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { layers, legends } = useAppSelector((state) => state.layers);
  const { meteograms } = useAppSelector((state) => state.meteograms);

  const makeKpiContainer = (layerId: string, value: number | null, color?: string | null) => {
    const layer = LayerManager.findLayer(layerId, layers.object);
    return (
      <KpiContainer key={layerId} name={layer ? layer.name : ''}>
        {color && <IndicatorIcon colour={color} size={20} radius={4} margin="-2px 8px" />}
        {textLayers.includes(layerId as any) &&
          layer &&
          layers.status === 'finished' &&
          `${value == null ? 'N/A' : ratingToName(value)}`}
        {!textLayers.includes(layerId as any) &&
          (layer && layers.status === 'finished'
            ? `${value == null ? 'N/A' : round(value, layer.precision)}${
                value != null && layer.units ? layer.units : ''
              }`
            : 'Invalid Layer')}
      </KpiContainer>
    );
  };

  const layersToShow: LayerManager.Layer.LayerIds[] = [
    'IDZ10136_AUS_AFDRS_max_fdr_SFC',
    'IDZ71127_AUS_DF_SFC',
    'IDZ10145_AUS_AFDRS_awap_upper_soil_moisture_SFC',
    'IDZ10148_AUS_FSE_curing_SFC',
    'IDZ10159_AUS_FSE_grass_fuel_load_SFC',
    'IDZ10149_AUS_FSE_grass_condition_SFC',
    'IDZ10162_AUS_FSE_time_since_fire_SFC',
    'IDZ10147_AUS_AFDRS_wcdi_flag_SFC',
    'IDZ10143_AUS_AFDRS_chaines_flag_SFC',
    'IDZ71014_AUS_DailyPrecip25Pct_SFC',
  ];

  useEffect(() => {
    layersToShow.forEach((id) => {
      const layer = LayerManager.findLayer(id, layers.object);
      if (layer && legends[id].status === 'idle') dispatch(LayerActions.getLegend({ layer }));
    });
  }, [layers]);

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div className={classes.section}>
        <Typography className={classes.heading}>Key Factors</Typography>
      </div>
      <div className={classes.lastSection}>
        {layersToShow.map((id) =>
          makeKpiContainer(
            id,
            meteograms.object?.keyFactors[id] ?? null,
            legends[id]?.object != null && meteograms.object?.keyFactors[id]
              ? WmsManager.getColour(meteograms.object.keyFactors[id], legends[id].object)
              : undefined,
          ),
        )}
      </div>
    </div>
  );
};

export default KeyFactors;
