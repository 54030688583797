import React from 'react';

import { makeStyles, createStyles, useTheme } from '@material-ui/core/styles';
import { Theme, Typography, Dialog, DialogContent } from '@material-ui/core';

import { Close } from '@material-ui/icons';
import { LayerList } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    popupTitle: {
      color: theme.palette.common.neutralDark,
    },
  }),
);

interface BasemapsBoundariesDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const BasemapsBoundariesDialog: React.FunctionComponent<BasemapsBoundariesDialogProps> = ({ open, setOpen }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      maxWidth="md"
      aria-labelledby="Layer Manager"
      aria-describedby="Manages the layers for the selected map"
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', margin: theme.spacing(2) }}>
        <Typography variant="h6" className={classes.popupTitle}>
          Basemaps &amp; Boundaries
        </Typography>
        <Close style={{ cursor: 'pointer', marginLeft: 'auto' }} onClick={() => setOpen(false)} />
      </div>
      <DialogContent>
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <LayerList displayMode="list" columns={1} hideBookmark filterFn={(l) => l.isBaseMap} />
          <LayerList displayMode="list" columns={1} hideBookmark filterFn={(l) => l.isBoundary} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

BasemapsBoundariesDialog.displayName = 'BasemapsBoundariesDialog';
export default BasemapsBoundariesDialog;
